/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

function Release({ data }) {
  const urlNextGen = "https://next-gen.tria.connectria.com";
  const urlNextGenDocs = "https://docs.next-gen.tria.connectria.com";
  return (
    <article
        className="release"
        itemScope
        itemType="http://schema.org/Article"
    >
        <header>
            <div className="flex-grid">
                <div className="col">
                    <h1 itemProp="headline" style={{flex:1}}>TRiA Version {data.frontmatter.version}</h1>
                </div>
                <div className="button-rtd" style={{flex:0, whiteSpace:"nowrap", marginRight:20}}>
                    <a href={urlNextGenDocs} className="button" target="_blank" rel="noreferrer" style={{backgroundColor:"#BFC5D2"}}>Read the Docs</a>
                </div>
                <div style={{flex:0, whiteSpace:"nowrap"}}>
                    <a href={urlNextGen} className="button" target="_blank" rel="noreferrer">Sign in to TRiA</a>
                </div>
            </div>
            <ul className="release-detail-list">
                <li><small>Released {data.frontmatter.date}</small></li>
                <li><small>URL: <a href={urlNextGen} target="_blank" rel="noreferrer">{urlNextGen}</a></small></li>
            </ul>
        </header>
        <section className="release-body"
            dangerouslySetInnerHTML={{ __html: data.html }}
            itemProp="articleBody"
        />
    </article>
  )
}

export default Release
